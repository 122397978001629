<template>
	<div class="trialinvitation" v-if="isdiv">
		<!-- 导航栏 -->
		<van-nav-bar
		class="nav_bar_color"
		title="邀请函详情"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		fixed
		z-index="11"
		placeholder 
		:border="false"
		/>
		
		<!-- 客户信息 -->
		<div style="height: 14px;"></div>
		<div class="customer">
			<div class="customer__send">
				<div class="customer__send__user">
					<div>发件人：{{info.username}}</div>
					<div style="opacity: 0.5;;">{{info.send_time}}</div>
				</div>
				<div class="customer__send__status">
					{{info.if_check == 0?'待确认':'已确认'}}
				</div>
			</div>
			<div class="customer__row">
				<div class="customer__row__title">客户姓名：</div>
				<div class="customer__row__con">{{info.customer_name}}</div>
				<img style="margin-left: 10px;" v-if="info.customer_gender == 1" src="@/assets/trial/icon_man.png">
				<img style="margin-left: 10px;" v-if="info.customer_gender == 0" src="@/assets/trial/icon_woman.png">
			</div>
			<div class="customer__row">
				<div class="customer__row__title">客户电话：</div>
				<div class="customer__row__con">{{info.customer_mobile}}</div>
			</div>
			<div class="customer__row" v-if="info.group_name">
				<div class="customer__row__title">用户分类：</div>
				<div class="customer__row__con">{{info.group_name}}</div>
			</div>
			<div class="customer__row">
				<div class="customer__row__title">意向车型：</div>
				<div class="customer__row__con">{{info.model_name}}</div>
				<div class="customer__row__chepai" v-if="info.license">车牌：{{info.license}}</div>
			</div>
		</div>
		
		<!-- 敬语 -->
		<div class="honorific">
			<div>{{info.content[0]}}</div>
			<div>{{info.content[1]}}</div>
		</div>
		
		<div class="service">
			<div class="service__title">
				我的专属服务
			</div>
			<div class="service__opt" v-for="item in info.store_service" :key="item.id">
				<img v-if="service_ids.indexOf(item.id) > -1" src="@/assets/drivroute/icon_xuandui.png">
				<img v-else src="@/assets/drivroute/icon_weixuanz.png">
				<div>{{item.name}}</div>
			</div>
		</div>
		
		<div class="shop">
			<img :src="info.program.image">
			<span>{{info.program.name}}</span>
		</div>
		
		<div class="shopaddr" v-if="islng_lat">
			<div class="shopaddr__map">
				<Map :center="info.lng_lat"></Map>
			</div>
			<div class="shopaddr__address">
				<div class="shopaddr__address__row1">
					{{info.store_name}}
				</div>
				<div class="shopaddr__address__row2">
					<img src="@/assets/drivroute/icon_dinwei.png">
					<span>{{info.address}}</span>
				</div>
				<div class="shopaddr__address__row2">
					<img src="@/assets/drivroute/icon_model.png">
					<span>{{info.contact}}</span>
				</div>
			</div>
		</div>
		
		<div class="adviser">
			<van-image class="center_avatar" round :src="info.avatar" fit="cover"/>
			<span class="adviser__title">{{info.bio}}</span>
			<span class="adviser__init">您的专属销售顾问{{info.username}}随时为您服务</span>
		</div>
		
		<!-- 保存 -->
		<div style="height: 72px;"></div>
		<div class="savebtn">
			<div @click="handSave">创建试驾</div>
		</div>
	</div>
</template>

<script>
import { getDateTime3,getDateTime7 } from '@/utils/utils.js'
import Map from '../../components/map.vue'
export default {
	components: {
		Map
	},
	data() {
		return {
			info: {
				id: 0
			},
			service_ids: [],
			islng_lat:true,
			isdiv:false
		};
	},
	mounted() {
		if (this.$route.query.id) this.info.id = this.$route.query.id
		this.getInviterInfo()
	},
	methods: {
		// 邀请详情
		async getInviterInfo(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_scsj_invitation_info_1_7_2', {
				id: this.info.id
			})
			if (result.code == 1) {
				this.info = { ...this.info, ...result.data.res }
				this.info.send_time = getDateTime7(this.info.send_time)
				this.info.program.image = this.serverUrl(this.info.program.image)
				this.info.avatar = this.serverUrl(this.info.avatar)
				if(this.info.scsj_store_service_ids != null){
					this.service_ids = this.info.scsj_store_service_ids.split(',').map(item => parseInt(item))
				}
				if(result.data.res.lng_lat != null){
					this.info.lng_lat = this.info.lng_lat.split(',')
				}else{
					this.islng_lat = false
				}
				
			}
			this.isdiv = true
		},
		
		// 保存
		handSave(e) {
			this.$router.push({
				path: `/trial-add?invitation_id=${this.info.id}`
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.trialinvitation{
	padding-bottom: 100px;
}
.customer{
	margin: 0 auto;
	width: 686px;
	box-sizing: border-box;
	padding: 32px;
	background: #222222;
	border-radius: 24px;
	&__send{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 32px;
		margin-bottom: 32px;
		border-bottom: 1px solid #3B3B3B;
		&__user{
			div:nth-child(1){
				font-size: 36px;
				font-family: HYQiHeiY3-55;
				color: #FFFFFF;
				line-height: 40px;
			}
			div:nth-child(2){
				margin-top: 6px;
				font-size: 28px;
				font-family: HYQiHeiY3-45;
				color: #FFFFFF;
				line-height: 32px;
			}
		}
		&__status{
			font-size: 44px;
			font-family: HYQiHeiY3-45;
			color: #F3C846;
		}
	}
	&__row{
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		height: 40px;
		&__title{
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			color: #FFFFFF;
		}
		&__con{
			font-size: 28px;
			font-family: Regular;
			color: #FFFFFF;
		}
		&__chepai{
			font-size: 28px;
			font-family: HYQiHeiY3-45;
			background: #F3C846;
			border-radius: 8px;
			width: 240px;
			height: 48px;
			line-height: 48px;
			text-align: center;
			font-weight: normal;
			color: #333333;
			margin-left: 20px;
		}
		img{
			margin-right: 20px;
			width: 32px;
			height: 32px;
		}
	}
}
.honorific{
	padding: 30px 32px;
	font-size: 32px;
	font-family: HYQiHeiY3-45;
	color: #FFFFFF;
	line-height: 48px;
}
.service{
	padding: 30px 32px;
	&__title{
		text-align: center;
		font-size: 40px;
		font-family: HYQiHeiY3-55;
		color: #FFFFFF;
		line-height: 48px;
		margin-bottom: 42px;
	}
	&__opt{
		width: 100%;
		background: #222222;
		box-sizing: border-box;
		border-radius: 8px;
		padding: 24px 28px;
		display: flex;
		font-size: 32px;
		font-family: HYQiHeiY3-45;
		color: #FFFFFF;
		line-height: 36px;
		margin-bottom: 20px;
		img{
			margin-top: 2px;
			margin-right: 20px;
			width: 32px;
			height: 32px;
			flex-shrink: 0;
		}
	}
}
.shop{
	border: 1px solid #979797;
	padding: 28px;
	margin: 0 32px;
	display: flex;
	align-items: center;
	img{
		height: 144px;
		width: 144px;
	}
	span{
		margin-left: 40px;
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		font-weight: normal;
		color: #FFFFFF;
	}
}
.shopaddr{
	padding: 40px 32px;
	display: flex;
	height: 242px;
	&__map{
		flex-shrink: 0;
		width: 242px;
		height: 242px;
		background: #fff;
	}
	&__address{
		margin-left: 20px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 10px 0;
		width: 400px;
		&__row1{
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			font-weight: normal;
			color: #ACACAC;
			line-height: 32px;
		}
		&__row2{
			display: flex;
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			color: #ACACAC;
			img{
				flex-shrink: 0;
				margin-top: 6px;
				width: 32px;
				height: 28px;
				margin-right: 6px;
			}
			span{
				overflow:hidden;
				text-overflow:ellipsis;
				display: -webkit-box; 
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2; 
			}
		}
	}
}
.adviser{
	padding: 40px;
	display: flex;
	align-items: center;
	flex-direction:column;
	.center_avatar{
		width: 120px;
		height: 120px;
		border: 1px solid #FA0037;
		border-radius: 50%;
	}
	&__title{
		margin-top: 32px;
		font-size: 40px;
		font-family: HYQiHeiY3-45;
		color: #FFFFFF;
		line-height: 40px;
	}
	&__init{
		margin-top: 32px;
		font-size: 32px;
		color: #BFBFBF;
		line-height: 32px;
	}
}
.savebtn{
	background: #191919;
	position: fixed;
	z-index: 99;
	bottom: 0;
	padding: 22px 40px;
	box-sizing: border-box;
	padding-bottom: calc(constant(safe-area-inset-bottom) + 22px);
	padding-bottom: calc(env(safe-area-inset-bottom) + 22px);
	width: 100%;
	div{
		width: 100%;
		background: #FA0037;
		text-align: center;
		line-height: 92px;
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		color: #FFFFFF;
	}
}
</style>
